import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class ConstructionsRepository extends BaseRepository {
    baseUrl = 'management/concessions/constructions';

    setType(type) {
        this.baseUrl = `management/concessions/constructions/${type}`;
    }

    /**
     * Returns all parents by type records
     *
     * @returns {*}
     */
    parentByType() {
        return this.httpClient.get(`${this.baseUrl}/parent-by-type`);
    }

    /**
     * Returns all by type records
     *
     * @returns {*}
     */
    allByType(type) {
        return this.httpClient.get(`management/concessions/constructions/${type}/all-by-type`);
    }

    /**
     * Returns all sub elements of an element
     *
     * @returns {*}
     */
    subElements(elementId) {
        return this.httpClient.get(`management/concessions/constructions/${elementId}/sub-elements`);
    }
}
